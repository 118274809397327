import React, { useMemo, useEffect } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  CellProps,
  Row,
} from "react-table";
import {
  MdAllInclusive,
  MdGroupOff,
  MdArrowDropDown,
  MdArrowDropUp,
  MdFilterList,
} from "react-icons/md";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TablePagination } from "@material-ui/core";

import { DeviceData } from "../../types";
import { UvLampOpMode, Color } from "../../constants";
import Device from "../../lib/Device";
import {
  opModeToTitle,
  opModeToDescription,
  opModeToColor,
  metadataToStatus,
  metadataToStatusTitle,
  metadataToStatusColor,
} from "../../utils/stringifiers";
import ColumnInfoPopover from "./ColumnInfoPopover";

import "./ZenerTable.scss";

function HeightInfo(): React.ReactElement {
  return (
    <div className="col-info">
      <p className="description">
        The distance between the face
        <br />
        of Zener&apos;s lamp and the floor
      </p>
    </div>
  );
}

function DisinfectionModeInfo(): React.ReactElement {
  const opModes = [UvLampOpMode.Continuous, UvLampOpMode.HumanNotDetected];

  return (
    <div className="col-info">
      {opModes.map((opMode) => (
        <>
          <h4 className="title" style={{ color: opModeToColor(opMode) }}>
            {opMode === UvLampOpMode.Continuous ? (
              <MdAllInclusive className="icon" />
            ) : (
              <MdGroupOff className="icon" />
            )}
            {opModeToTitle(opMode)}
          </h4>
          <p className="description">{opModeToDescription(opMode).join(" ")}</p>
        </>
      ))}
    </div>
  );
}

function StatusInfo(): React.ReactElement {
  const statuses = [true, false];

  const content: string[][] = statuses.map(metadataToStatus);
  const colors: string[] = statuses.map(metadataToStatusColor);

  return (
    <div className="col-info">
      {content.map(([title, ...description], i) => (
        <>
          <h4 className="title" style={{ color: colors[i] }}>
            {title}
          </h4>
          <p className="description">{description.join(" ")}</p>
        </>
      ))}
      <h4 className="title" style={{ color: Color.Red }}>
        Error
      </h4>
      <p className="description">
        There is a problem with Zener. Contact UVX
        <br />
        for mode details.
      </p>
    </div>
  );
}

function LampEnabledInfo(): React.ReactElement {
  return (
    <div className="col-info">
      <h4 className="title">Enabled</h4>
      <p className="description">
        Zener&apos;s lamp is enabled,
        <br />
        and may turn on to sanitize the area.
      </p>
      <h4 className="title">Disabled</h4>
      <p className="description">
        Zener&apos;s lamp is disabled,
        <br />
        and will not turn on under any circumstances.
      </p>
    </div>
  );
}

const columnInfos = [
  null,
  <HeightInfo key="height" />,
  <DisinfectionModeInfo key="disinfection" />,
  <StatusInfo key="status" />,
  <LampEnabledInfo key="lamp_en" />,
];

function NameCell(props: CellProps<DeviceData, [string, string]>) {
  const { value } = props;
  const [name, description] = value;

  let descVal = description || "";
  if (descVal.length > 30) {
    descVal = `${descVal.slice(0, 30)}...`;
  }

  return (
    <div className="name-cell">
      <span className="name">{name}</span>
      <span className="description">{descVal}</span>
    </div>
  );
}

function HeightCell(props: CellProps<DeviceData, number>) {
  const { value } = props;
  return (
    <div className="height-cell">
      <span className="height">
        {value}
        &nbsp; meters
      </span>
    </div>
  );
}

function OpModeCell(props: CellProps<DeviceData, string>) {
  const { value, row } = props;
  const label = value;

  const color = opModeToColor(row.original.uvLampOpMode);

  let Icon: null | React.FC<{ className?: string }> = null;
  switch (row.original.uvLampOpMode) {
    case UvLampOpMode.Continuous:
      Icon = MdAllInclusive;
      break;
    case UvLampOpMode.HumanNotDetected:
      Icon = MdGroupOff;
      break;
    default:
      break;
  }

  return (
    <div className="op-mode-cell" style={{ color }}>
      {Icon !== null && <Icon className="op-mode-icon" />}
      <span className="label">{label}</span>
    </div>
  );
}

function StatusCell(props: CellProps<DeviceData, [string, string]>) {
  const { value, row } = props;
  const label = value;

  const color = metadataToStatusColor(row.original.connected);

  return (
    <div className="status-cell" style={{ color }}>
      <div className="status-circle" style={{ backgroundColor: color }} />
      <span className="label">{label}</span>
    </div>
  );
}

function EnabledCell(
  props: CellProps<DeviceData, boolean> & {
    onSetEnabled: (deviceId: string) => void;
  },
) {
  const { value, onSetEnabled, row } = props;

  function handleEnabledChange() {
    onSetEnabled(row.original.deviceId);
  }

  function handleParentClick(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    evt: any,
  ) {
    evt.stopPropagation();
    evt.nativeEvent.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
  }

  return (
    <div className="enabled-cell" onClick={handleParentClick}>
      <Switch checked={value} onChange={handleEnabledChange} />
    </div>
  );
}

function enabledSort(rowA: Row<DeviceData>, rowB: Row<DeviceData>): number {
  const aEn = rowA.original.uvLampEnabled;
  const bEn = rowB.original.uvLampEnabled;

  if (aEn === bEn) {
    return 0;
  }

  return bEn ? 1 : -1;
}

function nameSort(rowA: Row<DeviceData>, rowB: Row<DeviceData>): number {
  const aName = rowA.original.name;
  const bName = rowB.original.name;

  return (aName || "").localeCompare(bName || "");
}

export interface ZenerTableProps {
  devices: Device[];
  devicesCount?: number | null;
  query: string;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    evt: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  onSelect: (deviceId: string) => void;
  onSetEnabled: (deviceId: string) => void;
}

export default function ZenerTable(props: ZenerTableProps): React.ReactElement {
  const {
    devices,
    query,
    onSelect,
    onSetEnabled,
    devicesCount,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
  } = props;

  const data: DeviceData[] = useMemo(
    () => devices.map((device) => device.toFlatObj()),
    [devices],
  );

  const columns = useMemo(
    () => [
      {
        Header: "Name and Description",
        Cell: NameCell,
        accessor: (row: DeviceData): [string | null, string] => [
          row.name,
          row.description,
        ],
        sortType: nameSort,
      },
      {
        Header: "Installation Height",
        accessor: "height",
        Cell: HeightCell,
      },
      {
        Header: "Operating Mode",
        id: "uvLampOpMode",
        accessor: (row: DeviceData): string => {
          const label = opModeToTitle(row.uvLampOpMode);

          return label;
        },
        Cell: OpModeCell,
      },
      {
        Header: "Status",
        id: "status",
        accessor: (row: DeviceData): string =>
          metadataToStatusTitle(row.connected),
        Cell: StatusCell,
      },
      {
        Header: "Lamp Enabled",
        accessor: "uvLampEnabled",
        Cell: EnabledCell,
        sortType: enabledSort,
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { columns: columns as any, data, onSetEnabled },
    useGlobalFilter,
    useSortBy,
  );

  useEffect(() => {
    setGlobalFilter(query);
  }, [query, data]);

  return (
    <Paper classes={{ root: "table-wrapper" }}>
      <Table className="table" {...getTableProps()}>
        <TableHead className="header">
          {headerGroups.map((hg) => {
            const hgProps = hg.getHeaderGroupProps();
            return (
              <TableRow {...hgProps} key={hgProps.key}>
                {hg.headers.map((col, colI) => {
                  const colProps = col.getHeaderProps(
                    col.getSortByToggleProps(),
                  );
                  return (
                    <TableCell
                      {...colProps}
                      align={colI > 0 ? "right" : undefined}
                      key={colProps.key}
                    >
                      <div className="header-cell">
                        {col.render("Header")}
                        <ColumnInfoPopover className="col-info-popover">
                          {columnInfos[colI]}
                        </ColumnInfoPopover>
                        {col.isSorted ? (
                          <>
                            {col.isSortedDesc ? (
                              <MdArrowDropUp className="sort-dir-icon" />
                            ) : (
                              <MdArrowDropDown className="sort-dir-icon" />
                            )}
                          </>
                        ) : (
                          <MdFilterList className="sort-icon" />
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <TableRow
                className="row"
                {...rowProps}
                onClick={() => onSelect(row.original.deviceId)}
                hover
                key={rowProps.key}
              >
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps();
                  return (
                    <TableCell {...cellProps} key={cellProps.key}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={devicesCount || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        onPageChange={onPageChange}
        onRowsPerPageChange={(event) => {
          const newRowsPerPage = parseInt(event.target.value, 10);
          onRowsPerPageChange(newRowsPerPage);
        }}
      />
    </Paper>
  );
}
