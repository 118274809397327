import React, { useEffect, useMemo, useRef, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TablePagination } from "@material-ui/core";
import "./Simulations.scss";

import { CancelTokenSource } from "axios";
import { MdArrowDropDown, MdArrowDropUp, MdFilterList } from "react-icons/md";
import { useRenderer } from "../../hooks/threeJs/useRenderer";
import { useResizeHandler } from "../../hooks/threeJs/useResizeHandler";
import { getRoomScans, getRoomScansCount } from "../../networking/room_scans";
import { getSimulations } from "../../networking/simulations";
import { ColQueryOp, RoomScan } from "../../types";
import { PageUrl } from "../../constants";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import { cancelRequest } from "../../utils/helpers";

import {
  useTable,
  useSortBy,
  useGlobalFilter,
  CellProps,
  Row,
} from "react-table";

interface RoomScanWithSimBoolean extends RoomScan {
  hasSimulations: boolean;
}

function genRoomScansDetailsUrl(scan_id: string): string {
  return `${PageUrl.SimulationDetails}?scan_id=${scan_id}`;
}

function DataCell(props: CellProps<RoomScan>) {
  const { value } = props;

  return (
    <div className="name-cell">
      <span className="name">{value}</span>
    </div>
  );
}

const Simulations: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const history = useHistory();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [roomScans, setRoomScans] = useState<RoomScanWithSimBoolean[]>([]);
  const [scansCount, setScansCount] = useState<number>(0);

  const roomScansCTSRef = useRef<CancelTokenSource | undefined>(undefined);
  const scansCountCTSRef = useRef<CancelTokenSource | undefined>(undefined);

  const [loading, setLoading] = useState(false);

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    nextPage: number,
  ) => {
    setPage(nextPage);
  };

  const { renderer, camera } = useRenderer(canvasRef);
  useResizeHandler(canvasRef, camera, renderer);

  function nameSort(rowA: Row<RoomScan>, rowB: Row<RoomScan>): number {
    const aName = rowA.original.name;
    const bName = rowB.original.name;
    return (aName || "").localeCompare(bName || "");
  }

  function handleRowClick(scanId: string) {
    history.push(genRoomScansDetailsUrl(scanId));
  }

  async function fetchRoomScans() {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cancelRequest(roomScansCTSRef as any);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cancelRequest(scansCountCTSRef as any);
    try {
      const offset = page * rowsPerPage;
      const nextRoomScans = await getRoomScans(
        {},
        roomScansCTSRef.current,
        offset,
        rowsPerPage,
      );
      const nextRoomScansCount = await getRoomScansCount(
        undefined,
        scansCountCTSRef.current,
      );

      // Check simulation availability
      const simulationsAvailability = nextRoomScans.map(async (scan) => {
        const simulations = await getSimulations({
          scan_id: { value: scan.scan_id, op: ColQueryOp.Eq },
        });
        return {
          scan_id: scan.scan_id,
          hasSimulations: simulations.length > 0,
        };
      });

      const availabilityResults = await Promise.all(simulationsAvailability);

      const nextRoomScansWithAvailability: RoomScanWithSimBoolean[] =
        nextRoomScans.map((scan) => {
          const availability = !!availabilityResults.find(
            (res) => res.scan_id === scan.scan_id,
          )?.hasSimulations;
          return {
            ...scan,
            hasSimulations: availability,
          };
        });

      setRoomScans(nextRoomScansWithAvailability);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setScansCount(nextRoomScansCount!);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchRoomScans();
  }, [page, rowsPerPage]);

  const columns = useMemo(
    () => [
      {
        Header: "Scan ID",
        accessor: (row: RoomScanWithSimBoolean): [string] => [row.scan_id],
        Cell: DataCell,
        sortType: nameSort,
      },
      {
        Header: "Name",
        accessor: (row: RoomScanWithSimBoolean): [string] => [row.name],
        Cell: DataCell,
        sortType: nameSort,
      },
      {
        Header: "Status",
        accessor: (row: RoomScanWithSimBoolean): string =>
          row.hasSimulations ? "Finished" : "Pending",
        Cell: DataCell,
        sortType: nameSort,
      },
      {
        Header: "Upload Date",
        accessor: (row: RoomScanWithSimBoolean): string => {
          const date = new Date(row.upload_date);
          const formattedDate =
            date.toDateString() + " " + date.toLocaleTimeString();
          const dateString = formattedDate ?? "";
          return dateString;
        },
        Cell: DataCell,
        sortType: nameSort,
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { columns: columns as any, data: roomScans },
      useGlobalFilter,
      useSortBy,
    );

  return (
    <div className="simulation-list">
      {loading ? (
        <Loading message="Loading data..." />
      ) : (
        <>
          <h1>List of Simulations</h1>
          <Paper classes={{ root: "table-wrapper" }}>
            <Table className="table" {...getTableProps()}>
              <TableHead className="header">
                {headerGroups.map((hg) => {
                  const hgProps = hg.getHeaderGroupProps();
                  return (
                    <TableRow {...hgProps} key={hgProps.key}>
                      {hg.headers.map((col, colI) => {
                        const colProps = col.getHeaderProps(
                          col.getSortByToggleProps(),
                        );
                        return (
                          <TableCell
                            {...colProps}
                            align={colI > 0 ? "left" : undefined}
                            key={colProps.key}
                          >
                            <div className="header-cell-simulation">
                              {col.render("Header")}
                              {col.isSorted ? (
                                <>
                                  {col.isSortedDesc ? (
                                    <MdArrowDropUp className="sort-dir-icon" />
                                  ) : (
                                    <MdArrowDropDown className="sort-dir-icon" />
                                  )}
                                </>
                              ) : (
                                <MdFilterList className="sort-icon" />
                              )}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();
                  return (
                    <TableRow
                      className="row"
                      {...rowProps}
                      onClick={() => handleRowClick(row.original.scan_id)}
                      hover
                      key={rowProps.key}
                    >
                      {row.cells.map((cell) => {
                        const cellProps = cell.getCellProps();
                        return (
                          <TableCell {...cellProps} key={cellProps.key}>
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={scansCount || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              onPageChange={handlePageChange}
              onRowsPerPageChange={(event) => {
                const newRowsPerPage = parseInt(event.target.value, 10);
                setRowsPerPage(newRowsPerPage);
              }}
            />
          </Paper>
        </>
      )}
    </div>
  );
};

export default Simulations;
