import { useCallback } from "react";
import { RoomDimension } from "../../pages/SimulationDetailsPage";
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/Addons";

const objectMaterialMap: Record<string, THREE.MeshPhongMaterial> = {
  wall: new THREE.MeshPhongMaterial({ color: 0xffffff }),
  floor: new THREE.MeshPhongMaterial({ color: 0xf5f5dc }),
  table: new THREE.MeshPhongMaterial({ color: 0xb5a41d }),
  chair: new THREE.MeshPhongMaterial({ color: 0xb58b1d }),
  storage: new THREE.MeshPhongMaterial({ color: 0xbea589 }),
};

const defaultMaterial = new THREE.MeshPhongMaterial({ color: 0xffffff });

const assignMaterialByName = (mesh: THREE.Mesh) => {
  const material = Object.keys(objectMaterialMap).find((key) =>
    mesh.name.toLowerCase().includes(key),
  );
  mesh.material = material ? objectMaterialMap[material] : defaultMaterial;
};

export const useModelLoader = (
  scene: THREE.Scene | null,
  camera: THREE.PerspectiveCamera | null,
  renderer: THREE.WebGLRenderer | null,
  modelUrl: string,
  roomDimension: RoomDimension,
) => {
  const loadModel = useCallback((): Promise<THREE.Object3D | null> => {
    return new Promise((resolve, reject) => {
      if (!scene || !camera || !renderer) {
        reject("Scene, camera, or renderer is not defined");
        return;
      }

      const loader = new OBJLoader();
      loader.load(
        modelUrl,
        (object) => {
          const boundingBox = new THREE.Box3().setFromObject(object);
          const center = boundingBox.getCenter(new THREE.Vector3());
          const size = boundingBox.getSize(new THREE.Vector3());

          object.traverse((child) => {
            if (child instanceof THREE.Mesh) {
              assignMaterialByName(child);
            }
          });

          // center is aligned with the vertical midpoint
          object.position.set(
            -center.x,
            roomDimension.y / 2 - center.y,
            -center.z,
          );
          scene.add(object);
          const axes = new THREE.AxesHelper(100);
          // position axes on the floor
          const floorYPosition = roomDimension.y / 2 - size.y / 2;
          axes.position.set(0, floorYPosition, 0);
          scene.add(axes);

          const scale = Math.max(
            roomDimension.x / size.x,
            roomDimension.y / size.y,
          );
          object.scale.setScalar(scale);

          const distance = size.length() * 1.5;
          camera.position.set(center.x, center.y, distance);
          camera.lookAt(center);
          renderer.render(scene, camera);
          resolve(object);
        },
        undefined,
        (error) => {
          console.error("Error loading model:", error);
          reject(error);
        },
      );
    });
  }, [scene, camera, renderer, modelUrl, roomDimension]);
  return { loadModel };
};
