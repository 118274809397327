import { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { AuthUser } from "../hocs/withAuthN";
import { ColQueryOp, DeviceFilter } from "../types";
import { DeviceNetUtils, ZenerNetUtils } from "../networking";
import Device from "../lib/Device";
import axios, { CancelTokenSource } from "axios";

export function useZeners(
  authUser: AuthUser | null,
  page?: number,
  rowsPerPage?: number,
  searchBarFilter?: string | null,
) {
  const [devices, setDevices] = useState<Device[]>([]);
  const [devicesCount, setDevicesCount] = useState<number | null>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [focusedDeviceId, setFocusedDeviceId] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);

  const fetchDevices = async () => {
    if (authUser === null) return;
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel();
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();

    try {
      setLoading(true);
      const offset =
        page !== undefined && rowsPerPage !== undefined
          ? page * rowsPerPage
          : undefined;
      const filter: DeviceFilter = searchBarFilter
        ? { name: { value: searchBarFilter, op: ColQueryOp.FuzzyEq } }
        : {};

      const nextDevices = await DeviceNetUtils.getDevicesAll(
        offset,
        rowsPerPage,
        filter,
        cancelTokenSourceRef.current,
      );
      const counts = await DeviceNetUtils.getDeviceCount(
        filter,
        cancelTokenSourceRef.current,
      );

      if (nextDevices === null) return;

      nextDevices.sort((a: Device, b: Device) => {
        const av = a.metadata.createdAt;
        const bv = b.metadata.createdAt;

        if (av < bv) {
          return 1;
        }
        if (av > bv) {
          return -1;
        }
        return 0;
      });

      setDevicesCount(counts);
      setDevices(nextDevices);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error loading devices", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleSetEnabled = async (deviceId: string) => {
    const prev = devices.find((d) => d.deviceId() === deviceId);
    if (!prev) {
      console.error(new Error("Device undefined"));
      return;
    }

    const nextChecked = !prev.control.uvLampEnabled;
    try {
      setLoading(true);
      await ZenerNetUtils.postControl(deviceId, nextChecked);
      enqueueSnackbar("Updated device", { variant: "success" });
      fetchDevices(); // Reload the current page of devices
    } catch (error) {
      console.error(new Error("Device control undefined"));
      enqueueSnackbar("Failed to update device", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDevices();
    return () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, [authUser, page, searchBarFilter, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    devices,
    devicesCount,
    loading,
    focusedDeviceId,
    setFocusedDeviceId,
    handleSetEnabled,
    fetchDevices,
  };
}
