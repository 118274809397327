import React from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import "./App.scss";

import useDocTitle from "../../hooks/useDocTitle";
import { PageUrl } from "../../constants";
import withAuthN from "../../hocs/withAuthN";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import SignInPage from "../SignInPage";
import Reports from "../Reports";
import Account from "../AccountPage";
import Dashboard from "../DashboardPage";
import SimulationDetails from "../SimulationDetailsPage";
import Simulations from "../SimulationsPage";

function App(): React.ReactElement {
  useDocTitle();

  const location = useLocation();
  const history = useHistory();

  function handleTabSelect(
    event: React.ChangeEvent<Record<string, unknown>>,
    nextTab: string,
  ) {
    history.push(nextTab);
  }

  return (
    <div className="app">
      <div className="content-wrapper">
        <div className="content-wrapper-inner">
          <Navbar handleTabSelect={handleTabSelect} location={location} />
          <Switch>
            <Route exact path={PageUrl.Dashboard}>
              <Dashboard />
            </Route>
            <Route exact path={PageUrl.Account}>
              <Account />
            </Route>
            <Route exact path={PageUrl.SignIn}>
              <SignInPage />
            </Route>
            <Route exact path={PageUrl.Reports}>
              <Reports />
            </Route>
            <Route exact path={PageUrl.SimulationDetails}>
              <SimulationDetails />
            </Route>
            <Route exact path={PageUrl.Simulations}>
              <Simulations />
            </Route>
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withAuthN(App);
