import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import parseISO from "date-fns/parseISO";
import axios from "./axios";
import { DeviceFilter, DeviceMetadata } from "../types";
import * as ZenerNetUtils from "./zener";
import Device from "../lib/Device";
import { DeviceConnNetUtils } from ".";

const DEVICES_URL = "/devices";
const GET_DEVICES_URL = `${DEVICES_URL}/query`;
const GET_DEVICES_COUNT_URL = `${GET_DEVICES_URL}/count`;

export async function getDeviceMetadatas(
  filter?: DeviceFilter,
  queryParams?: URLSearchParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<DeviceMetadata[] | null> {
  try {
    const url = queryParams
      ? `${GET_DEVICES_URL}?${queryParams.toString()}`
      : GET_DEVICES_URL;

    const resp: AxiosResponse = await axios.post(url, filter || {}, {
      cancelToken: cancelTokenSource?.token,
    });

    if (!Array.isArray(resp.data)) {
      console.warn(`${GET_DEVICES_URL} payload not array: `, resp.data);
      return null;
    }
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    return resp.data.map((d: any) => ({
      deviceId: d.device_id,
      name: d.name,
      description: d.description,
      version: d.version,
      ownerId: d.owner_id,
      connected: d.connected,
      createdAt: parseISO(d.created_at),
      updatedAt: parseISO(d.updated_at),
      height: d.height,
    }));
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    } else {
      console.error("Error fetching device metadata:", error);
      throw error; // Re-throw error to be handled by the calling function
    }
  }
}

export async function getDeviceCount(
  filter?: DeviceFilter,
  cancelTokenSource?: CancelTokenSource,
): Promise<number | null> {
  try {
    const resp: AxiosResponse = await axios.post(
      GET_DEVICES_COUNT_URL,
      filter,
      {
        cancelToken: cancelTokenSource?.token,
      },
    );

    if (resp.data.count === undefined || resp.data.count === null) {
      console.warn(
        `${GET_DEVICES_COUNT_URL} payload did not return a valid count:`,
        resp.data,
      );
      return 0;
    }

    return resp.data.count;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    } else {
      console.error("Error fetching device count:", error);
      throw error;
    }
  }
}

export async function postDevice(
  deviceId: string,
  name?: string,
  description?: string,
  height?: number,
  cancelTokenSource?: CancelTokenSource,
): Promise<AxiosResponse | null> {
  try {
    const payload = {
      device_id: deviceId,
      name,
      description,
      height,
    };

    return await axios.post(DEVICES_URL, payload, {
      cancelToken: cancelTokenSource?.token,
    });
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export async function putDevice(
  deviceId: string,
  name: string,
  description: string,
  version: string,
  height: number,
  cancelTokenSource?: CancelTokenSource,
): Promise<AxiosResponse | null> {
  try {
    const payload = {
      device_id: deviceId,
      name,
      description,
      version,
      height,
    };

    return await axios.put(DEVICES_URL, payload, {
      cancelToken: cancelTokenSource?.token,
    });
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export function applyPaginationParams(
  queryParams: URLSearchParams,
  offset?: number,
  count?: number,
): URLSearchParams {
  if (offset !== undefined) {
    queryParams.set("offset", offset.toString());
  }
  if (count !== undefined) {
    queryParams.set("count", count.toString());
  }
  return queryParams;
}

export async function getDevicesAll(
  offset?: number,
  count?: number,
  filter?: DeviceFilter, // Added filter parameter
  cancelTokenSource?: CancelTokenSource,
): Promise<Device[] | null> {
  // Construct query parameters for pagination
  const queryParams = new URLSearchParams();

  applyPaginationParams(queryParams, offset, count);
  try {
    // Fetch device metadata using the updated function
    const metadatas = await getDeviceMetadatas(
      filter,
      queryParams,
      cancelTokenSource,
    );

    if (!metadatas) {
      return []; // Return empty array if no metadata is fetched
    }

    const deviceIds = metadatas.map((m) => m.deviceId);

    const ctrlsPromise = ZenerNetUtils.getControls(deviceIds);
    const statusesPromise = ZenerNetUtils.getStatuses(deviceIds);
    const connEvtsPromise = DeviceConnNetUtils.getDevicesConnected(deviceIds);

    const [ctrls, statuses, connEvts] = await Promise.all([
      ctrlsPromise,
      statusesPromise,
      connEvtsPromise,
    ]);

    const devices: Device[] = [];
    metadatas.forEach((m: DeviceMetadata) => {
      const ctrl = ctrls.find((c) => c?.deviceId === m.deviceId);
      const status = statuses.find((s) => s?.deviceId === m.deviceId);
      const connEvt = connEvts.find((s) => s?.device_id === m.deviceId) || null;

      if (!ctrl || !status) {
        console.warn(`Device "${m.deviceId}" has a null control or status`);
        return;
      }

      const device = new Device(m, ctrl, status, connEvt);
      devices.push(device);
    });

    return devices;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    } else {
      console.error("Error fetching devices:", error);
      throw error;
    }
  }
}
