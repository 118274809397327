import React, { useState } from "react";
import ChangePasswordModal from "../../components/ChangePasswordModal";
import useDocTitle from "../../hooks/useDocTitle";
import "./Account.scss";
import useAuth from "../../hooks/useAuth";

export default function AccountPage(): React.ReactElement {
  const { authUser } = useAuth();
  const [resetPass, setResetPass] = useState<boolean>(false);
  useDocTitle();
  return (
    <div className="account">
      <div className="content-wrapper">
        <div className="account-settings">
          <span className="account-settings-title">ACCOUNT SETTINGS</span>
          <div className="account-settings-details">
            <div className="details-title">
              <div className="details-title-left">
                <span className="title-span">LOGIN DETAILS</span>
                <span className="title-span-etc">
                  E-mail login and password
                </span>
              </div>
              <button
                type="button"
                onClick={() => {
                  setResetPass(true);
                }}
              >
                edit
              </button>
            </div>
            <div className="details-user">
              <span className="title-span">E-MAIL</span>
              <span className="user-span">{authUser?.attributes.email}</span>
            </div>
            <div className="details-pass">
              <span className="title-span">PASSWORD</span>
              <input disabled readOnly type="password" value="password" />
              <ChangePasswordModal open={resetPass} setOpen={setResetPass} />
            </div>
          </div>
        </div>

        <div className="account-billing">
          <span className="account-billing-title">BILLING</span>
          <div className="account-billing-price">
            <span className="account-billing-price-amount">$49.99</span>
            <span className="account-billing-price-desc">
              /monthly per Zener
            </span>
            <button type="button" className="account-billing-options">
              view billing details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
