import React, { useState, useMemo, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import dateCompareAsc from "date-fns/compareAsc";

import "./Reports.scss";

import loadingIcon from "../../icons/status.png";
import * as ZenerNetUtils from "../../networking/zener";
import useDocTitle from "../../hooks/useDocTitle";
import Loading from "../../components/Loading";
import { Color } from "../../constants";
import Device from "../../lib/Device";
import Button from "../../components/Button";
import { DFTPlotDataItem, AggZenerMetric } from "../../types";

import DisinfectionTimePlot from "../../components/DisinfectionTimePlot";
import { useSnackbar } from "notistack";
import { DeviceNetUtils } from "../../networking";
import useAuth from "../../hooks/useAuth";

const ALL_DEVICES_ID = "all";
const DATA_TIME_RANGE_DAYS = 7;

function mergeLampFiringAndOcc(
  disinfectDailyHrs: AggZenerMetric[] | null,
  occupiedDailyHrs: AggZenerMetric[] | null,
): DFTPlotDataItem[] {
  if (disinfectDailyHrs === null || occupiedDailyHrs === null) {
    return [];
  }

  const dailyHrs: DFTPlotDataItem[] = disinfectDailyHrs.map(
    (ddh: AggZenerMetric) => {
      const plotItem: DFTPlotDataItem = {
        datetime: ddh.from,
        disinfectTimeHrs: ddh.metric.reduce(
          (acc, curr) => acc + curr.result,
          0,
        ),
        occupiedTimeHrs: 0,
      };
      const odh = occupiedDailyHrs.find(
        (o) => dateCompareAsc(o.from, ddh.from) === 0,
      );
      if (odh !== undefined) {
        plotItem.occupiedTimeHrs = odh.metric.reduce(
          (acc, curr) => acc + curr.result,
          0,
        );
      }
      return plotItem;
    },
  );

  return dailyHrs;
}

function a11yProps(index: number) {
  return {
    id: `reports-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function reportTabs(devices: Device[]): React.ReactElement[] {
  return devices.map((device) => (
    <Tab
      key={device.deviceId()}
      value={device.deviceId()}
      label={device.metadata.name}
    />
  ));
}

interface MetricItemProps {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fmt?: (val: any) => string;
}
function MetricItem(props: MetricItemProps): React.ReactElement {
  const { label, value, fmt } = props;

  const valFmt: string = useMemo(() => {
    if (fmt === undefined) {
      return value;
    }

    return fmt(value);
  }, [fmt, value]);

  return (
    <Paper className="reports-metric-item" elevation={10}>
      <span className="reports-metric-item-value">{valFmt}</span>
      <span className="reports-metric-item-label">{label}</span>
    </Paper>
  );
}

export interface ReportProps {
  devices: Device[];
  activeDeviceId: string;
}
export function Report(props: ReportProps): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { devices, activeDeviceId } = props;
  const [graphData, setGraphData] = useState<DFTPlotDataItem[]>([]);
  const [disinfectSum, setDisinfectSum] = useState<number | null>(null);
  const [disinfectDayAvg, setDisinfectDayAvg] = useState<number | null>(null);
  const [disDayDeviceAvg, setDisDayDeviceAvg] = useState<number | null>(null);
  const [occupiedSum, setOccupiedSum] = useState<number | null>(null);
  const [occupiedDayAvg, setOccupiedDayAvg] = useState<number | null>(null);
  const [occDayDeviceAvg, setOccDayDeviceAvg] = useState<number | null>(null);
  const [graphHeight, setGraphHeight] = useState<number>(24);
  const [loading, setLoading] = useState(false);

  async function fetchData() {
    let deviceIds;
    if (activeDeviceId === ALL_DEVICES_ID) {
      deviceIds = devices.map((device) => device.metadata.deviceId);
      setGraphHeight(devices.length * 25);
    } else {
      deviceIds = [activeDeviceId];
      setGraphHeight(25);
    }

    const endDate = new Date();
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(startDate.getDate() - (DATA_TIME_RANGE_DAYS - 1));

    try {
      setLoading(true);
      const disinfectDailyHrsPromise: Promise<AggZenerMetric[] | null> =
        ZenerNetUtils.getDailyHours(
          deviceIds,
          startDate,
          endDate,
          "uv_lamp_firing",
        );
      const occupiedDailyHrsPromise: Promise<AggZenerMetric[] | null> =
        ZenerNetUtils.getDailyHours(
          deviceIds,
          startDate,
          endDate,
          "human_detected",
        );

      const [disinfectDailyHrs, occupiedDailyHrs] = await Promise.all([
        disinfectDailyHrsPromise,
        occupiedDailyHrsPromise,
      ]);

      const dailyHours = mergeLampFiringAndOcc(
        disinfectDailyHrs,
        occupiedDailyHrs,
      );
      setGraphData(dailyHours);

      const hrsDisinfectingPromise = ZenerNetUtils.getCumulativeHours(
        deviceIds,
        startDate,
        endDate,
        "uv_lamp_firing",
      );
      const hrsOccupiedPromise = ZenerNetUtils.getCumulativeHours(
        deviceIds,
        startDate,
        endDate,
        "human_detected",
      );
      const [hrsDisinfecting, hrsOccupied] = await Promise.all([
        hrsDisinfectingPromise,
        hrsOccupiedPromise,
      ]);
      if (hrsDisinfecting) {
        const total = hrsDisinfecting.metric.reduce(
          (acc, curr) => acc + curr.result,
          0,
        );
        setDisinfectSum(total);
        setDisinfectDayAvg(total / DATA_TIME_RANGE_DAYS);
        setDisDayDeviceAvg(total / DATA_TIME_RANGE_DAYS / deviceIds.length);
      }
      if (hrsOccupied) {
        const total = hrsOccupied.metric.reduce(
          (acc, curr) => acc + curr.result,
          0,
        );
        setOccupiedSum(total);
        setOccupiedDayAvg(total / DATA_TIME_RANGE_DAYS);
        setOccDayDeviceAvg(total / DATA_TIME_RANGE_DAYS / deviceIds.length);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [activeDeviceId]);

  return (
    <div className="reports-report">
      {loading ? (
        <Loading message="Loading data..." />
      ) : (
        <div className="reports-dg-rows">
          <div className="reports-dg-r">
            <DisinfectionTimePlot
              className="dft-plot"
              data={graphData}
              height={360}
              ymax={graphHeight}
            />
          </div>
          <div className="reports-dg-r">
            <MetricItem
              label="Total hours of disinfection"
              value={disinfectSum?.toFixed(1) || 0}
            />
            <MetricItem
              label="Average hours of disinfection per day"
              value={disinfectDayAvg?.toFixed(1) || 0}
            />
            {activeDeviceId === ALL_DEVICES_ID && (
              <MetricItem
                label="Average hours of disinfection per day per device"
                value={disDayDeviceAvg?.toFixed(1) || 0}
              />
            )}
          </div>
          <div className="reports-dg-r">
            <MetricItem
              label="Total hours of occupancy"
              value={occupiedSum?.toFixed(1) || 0}
            />
            <MetricItem
              label="Average hours of occupancy per day"
              value={occupiedDayAvg?.toFixed(1) || 0}
            />
            {activeDeviceId === ALL_DEVICES_ID && (
              <MetricItem
                label="Average hours of occupancy per day per device"
                value={occDayDeviceAvg?.toFixed(1) || 0}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default function Reports(): React.ReactElement {
  const { authUser } = useAuth();
  const [tab, setTab] = useState(ALL_DEVICES_ID);
  const [devices, setDevices] = useState<Device[]>([]);
  const [repLoading, setRepLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useDocTitle();

  async function fetchDevices() {
    try {
      setLoading(true);

      const nextDevices = await DeviceNetUtils.getDevicesAll();
      // No devices.
      if (nextDevices === null) {
        return;
      }

      nextDevices.sort((a: Device, b: Device) => {
        const av = a.metadata.createdAt;
        const bv = b.metadata.createdAt;

        if (av < bv) {
          return 1;
        }
        if (av > bv) {
          return -1;
        }
        return 0;
      });

      setDevices(nextDevices);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error loading devices", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  const filtDevices: Device[] = useMemo(() => {
    if (tab === ALL_DEVICES_ID) {
      return devices;
    }
    const f = devices.find((d) => d.deviceId() === tab);
    if (f === undefined) {
      return [];
    }

    return [f];
  }, [devices, tab]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function handleTab(_evt: any, nextTab: string) {
    setTab(nextTab);
  }

  async function handleDownloadReport() {
    setRepLoading(true);
    await ZenerNetUtils.downloadReport(tab);
    setRepLoading(false);
  }

  useEffect(() => {
    if (authUser === null) {
      return;
    }
    fetchDevices();
  }, [authUser]);

  return (
    <div className="reports-root">
      {devices ? (
        <div className="reports-content-container">
          <Tabs
            value={tab}
            onChange={handleTab}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: { backgroundColor: Color.Teal, height: "5px" },
            }}
          >
            <Tab label="All Zeners" value={ALL_DEVICES_ID} {...a11yProps(0)} />
            {reportTabs(devices)}
          </Tabs>
          <Report devices={filtDevices} activeDeviceId={tab} />
          <Button
            className="download-btn"
            color="secondary"
            onClick={handleDownloadReport}
          >
            {repLoading ? (
              <img className="loading-image" src={loadingIcon} alt="Loading" />
            ) : (
              "Download"
            )}
          </Button>
          {loading && <Loading overlay message="Updating device..." />}
        </div>
      ) : (
        <div className="no-devices">No Zeners found.</div>
      )}
    </div>
  );
}
