import React, { useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const FOV = 75; // Field of view in degrees
const NEAR_CLIPPING_PLANE = 1; // Objects closer
const FAR_CLIPPING_PLANE = 1000; // Objects further
const AMBIENT_LIGHT_INTENSITY = 1.5;
const DIRECTIONAL_LIGHT_INTENSITY = 2;

// Hook for rendering
export const useRenderer = (canvasRef: React.RefObject<HTMLCanvasElement>) => {
  const [renderer, setRenderer] = useState<THREE.WebGLRenderer | null>(null);
  const [scene, setScene] = useState<THREE.Scene | null>(null);
  const [camera, setCamera] = useState<THREE.PerspectiveCamera | null>(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;

    // Create a new scene
    const newScene = new THREE.Scene();

    // Set up a perspective camera
    const newCamera = new THREE.PerspectiveCamera(
      FOV,
      canvas.clientWidth / canvas.clientHeight,
      NEAR_CLIPPING_PLANE,
      FAR_CLIPPING_PLANE,
    );

    newCamera.position.set(0, 0, 100);

    // Create a WebGL renderer and pass the canvas
    const newRenderer = new THREE.WebGLRenderer({ canvas });
    newRenderer.setSize(canvas.clientWidth, canvas.clientHeight);
    newRenderer.setClearColor(0xeeeeee, 1);

    // Add controls for orbiting
    const controls = new OrbitControls(newCamera, newRenderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;

    // Add basic lighting
    const ambientLight = new THREE.AmbientLight(
      0x404040,
      AMBIENT_LIGHT_INTENSITY,
    );
    const directionalLight = new THREE.DirectionalLight(
      0xffffff,
      DIRECTIONAL_LIGHT_INTENSITY,
    );
    directionalLight.position.set(1, 1, 1).normalize();

    newScene.add(ambientLight);
    newScene.add(directionalLight);

    setScene(newScene);
    setCamera(newCamera);
    setRenderer(newRenderer);

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      newRenderer.render(newScene, newCamera);
    };
    animate();

    return () => {
      newRenderer.dispose();
    };
  }, [canvasRef]);

  return { renderer, scene, camera };
};
