import { UvLampOpMode } from "./constants";

export type HashMap<T> = { [key: string]: T };

export interface ZenerStatus {
  deviceId?: string;
  humanDetected: boolean;
  uvLampFiring: boolean;
  evtTimestamp: Date;
}

export interface ZenerMetrics {
  lampFiringTimeSec: number;
}

export interface ZenerControl {
  deviceId?: string;
  uvLampEnabled: boolean;
  uvLampOpMode: UvLampOpMode;
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export interface DeviceMetadata {
  deviceId: string;
  name: string | null;
  description: string;
  version: string;
  ownerId: string;
  createdAt: Date;
  updatedAt: Date;
  height: number;
}

export interface AggZenerMetric {
  from: Date;
  to: Date;
  metric: {
    deviceId: string;
    result: number;
  }[];
}

export interface DFTPlotDataItem {
  datetime: Date;
  disinfectTimeHrs: number;
  occupiedTimeHrs: number;
}

export type DeviceMetadataAndControl = DeviceMetadata & ZenerControl;

/* eslint-disable @typescript-eslint/indent */
export type DeviceData = DeviceMetadata &
  ZenerControl & {
    connected: boolean;
  };
/* eslint-enable @typescript-eslint/indent */

export interface DeviceConnEvt {
  device_id: string;
  connected: boolean;
  evt_ts: Date;
}

export enum ColQueryOp {
  Eq = "eq",
  FuzzyEq = "fuzzy_eq",
  Gt = "gt",
  Ln = "ln",
  Overlap = "overlap",
}

export interface ColQuery<T> {
  value: T | T[];
  op: ColQueryOp;
}

export interface DeviceFilter {
  name?: {
    value: string;
    op: ColQueryOp;
  };
}

export interface ScanFiles {
  json: string;
  mp4: string;
  obj: string;
}

export interface RoomScan {
  scan_id: string;
  owner_id: string;
  name: string;
  upload_date: Date;
  s3_key: string;
  files: ScanFiles;
}

export type DbQuery<T> = {
  [K in keyof T]?: ColQuery<T[K]>;
};

export interface ZenerPosition {
  x: number;
  y: number;
  z: number;
}
export enum ReviewStatus {
  Finished = "finished",
  Pending = "pending",
  InfoNeeded = "info_needed",
}
export interface Simulation {
  simulation_id: string;
  scan_id: string;
  owner_id: string;
  name: string;
  zener_positions: Array<ZenerPosition>;
  upload_date: Date;
  review_status: ReviewStatus;
}
