import Axios, { CancelTokenSource } from "axios";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getRoomDimension(roomData: any) {
  // should use polygon data for the floor
  const polygonCorners = roomData.floors[0].polygonCorners;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const xValues = polygonCorners.map((corner: any) => corner[0]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const yValues = polygonCorners.map((corner: any) => corner[1]);

  const minX = Math.min(...xValues);
  const maxX = Math.max(...xValues);
  const minY = Math.min(...yValues);
  const maxY = Math.max(...yValues);

  const roomWidth = maxX - minX;
  const roomDepth = maxY - minY;

  const walls = roomData.walls;
  // assume that all walls are the same size
  const roomHeight = walls.length > 0 ? walls[0].dimensions[1] : 0;

  return {
    x: roomWidth,
    y: roomHeight,
    z: roomDepth,
  };
}

export function cancelRequest(cts: React.MutableRefObject<CancelTokenSource>) {
  if (cts.current !== undefined) {
    cts.current.cancel();
  }
  cts.current = Axios.CancelToken.source();
}
