import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import axios from "./axios";
import { DbQuery, Simulation } from "../types";

const SIMULATIONS_URL = "/simulations";
const QUERY_SIMULATIONS_URL = SIMULATIONS_URL + "/query";

export type GetSimulationsQuery = DbQuery<Simulation>;

// Fetch Simulations for a list of completed simulations with Zener coordinates
export async function getSimulations(
  query: GetSimulationsQuery,
  cancelTokenSource?: CancelTokenSource,
  offset?: number,
  count?: number,
): Promise<Simulation[]> {
  try {
    const resp: AxiosResponse = await axios.post(QUERY_SIMULATIONS_URL, query, {
      params: {
        offset,
        count,
      },
      cancelToken: cancelTokenSource?.token,
    });

    if (!Array.isArray(resp.data)) {
      console.warn(`${SIMULATIONS_URL} payload is not an array:`, resp.data);
      return [];
    }

    return resp.data as Simulation[];
  } catch (error) {
    {
      if (Axios.isCancel(error)) {
        return [];
      }
      throw error;
    }
  }
}
