import React, { useEffect } from "react";
import * as THREE from "three";

// Hook to handle window resizing
export const useResizeHandler = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  camera: THREE.PerspectiveCamera | null,
  renderer: THREE.WebGLRenderer | null,
) => {
  useEffect(() => {
    const handleResize = () => {
      if (!canvasRef.current || !camera || !renderer) return;

      const canvas = canvasRef.current;
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(canvas.clientWidth, canvas.clientHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [canvasRef, camera, renderer]);
};
