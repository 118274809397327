import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import dateParseISO from "date-fns/parseISO";
import axios from "./axios";
import {
  ZenerStatus,
  ZenerControl,
  ZenerMetrics,
  AggZenerMetric,
} from "../types";
import { UvLampOpMode } from "../constants";

const ZENER_CONTROL_URL = "/zener/control";
const ZENER_CONTROLS_URL = "/zener/controls";
const ZENER_STATUS_URL = "/zener/status";
const ZENER_STATUSES_URL = "/zener/statuses";
const ZENER_FIRING_TIME_URL = "/zener/lamp-firing-time";
const ZENER_DAILY_HRS_URL = "/zener/daily-hours";
const ZENER_CUMULATIVE_HRS_URL = "/zener/cumulative-hours";

export async function getControl(
  deviceId: string,
  cancelTokenSource?: CancelTokenSource,
): Promise<ZenerControl | null> {
  try {
    const resp: AxiosResponse = await axios.get(ZENER_CONTROL_URL, {
      params: { device_id: deviceId },
      cancelToken: cancelTokenSource?.token,
    });

    const { data } = resp;

    const control: ZenerControl = {
      uvLampEnabled: data.uv_lamp_enabled,
      uvLampOpMode: data.uv_lamp_op_mode,
    };
    return control;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export async function getControls(
  deviceIds: string[],
  cancelTokenSource?: CancelTokenSource,
): Promise<(ZenerControl | null)[]> {
  try {
    const resp: AxiosResponse = await axios.post(
      ZENER_CONTROLS_URL,
      {
        device_ids: deviceIds,
      },
      {
        cancelToken: cancelTokenSource?.token,
      },
    );

    const { data } = resp;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return data.map((ctrl: any) => {
      if (ctrl === null) {
        return null;
      }

      const control: ZenerControl = {
        deviceId: ctrl.device_id,
        uvLampEnabled: ctrl.uv_lamp_enabled,
        uvLampOpMode: ctrl.uv_lamp_op_mode,
      };
      return control;
    });
  } catch (error) {
    if (Axios.isCancel(error)) {
      return [];
    }

    throw error;
  }
}

export async function postControl(
  deviceId: string,
  uvLampEnabled?: boolean,
  uvLampOpMode?: UvLampOpMode,
  cancelTokenSource?: CancelTokenSource,
): Promise<AxiosResponse | null> {
  try {
    const payload = {
      device_id: deviceId,
      uv_lamp_enabled: uvLampEnabled,
      uv_lamp_op_mode: uvLampOpMode,
    };

    return await axios.post(ZENER_CONTROL_URL, payload, {
      cancelToken: cancelTokenSource?.token,
    });
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export async function getStatus(
  deviceId: string,
  cancelTokenSource?: CancelTokenSource,
): Promise<ZenerStatus | null> {
  try {
    const resp: AxiosResponse = await axios.get(ZENER_STATUS_URL, {
      params: { device_id: deviceId },
      cancelToken: cancelTokenSource?.token,
    });

    const { data } = resp;

    const status: ZenerStatus = {
      humanDetected: data.human_detected,
      uvLampFiring: data.uv_lamp_firing,
      evtTimestamp: data.evt_timestamp,
    };
    return status;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export async function getStatuses(
  deviceIds: string[],
  cancelTokenSource?: CancelTokenSource,
): Promise<(ZenerStatus | null)[]> {
  try {
    const resp: AxiosResponse = await axios.post(
      ZENER_STATUSES_URL,
      {
        device_ids: deviceIds,
      },
      {
        cancelToken: cancelTokenSource?.token,
      },
    );

    const { data } = resp;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const status: (ZenerStatus | null)[] = data.map((s: any | null) => {
      if (s === null) {
        return null;
      }
      return {
        deviceId: s.device_id,
        humanDetected: s.human_detected,
        uvLampFiring: s.uv_lamp_firing,
        evtTimestamp: s.evt_timestamp,
      };
    });
    return status;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return [];
    }

    throw error;
  }
}

export async function getTotalFiringTimeSec(
  deviceId: string,
  cancelTokenSource?: CancelTokenSource,
): Promise<ZenerMetrics | null> {
  const resp: AxiosResponse = await axios.get(ZENER_FIRING_TIME_URL, {
    params: { device_id: deviceId },
    cancelToken: cancelTokenSource?.token,
  });

  const { data } = resp;

  if (
    data === undefined ||
    data === null ||
    !Array.isArray(data) ||
    data.length === 0
  ) {
    return null;
  }

  return {
    lampFiringTimeSec: data[0].firing_time_s,
  };
}

export async function getDailyHours(
  deviceIds: string[],
  from: Date,
  to: Date,
  metricName: string,
  cancelTokenSource?: CancelTokenSource,
): Promise<AggZenerMetric[] | null> {
  try {
    const payload = {
      device_ids: deviceIds,
      col: metricName,
      from: from.toISOString(),
      to: to.toISOString(),
    };

    const resp: AxiosResponse = await axios.get(ZENER_DAILY_HRS_URL, {
      params: payload,
      cancelToken: cancelTokenSource?.token,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return resp.data.map((dh: any) => ({
      ...dh,
      from: dateParseISO(dh.from),
      to: dateParseISO(dh.to),
    }));
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export async function getCumulativeHours(
  deviceIds: string[],
  from: Date,
  to: Date,
  statusType: string,
  cancelTokenSource?: CancelTokenSource,
): Promise<AggZenerMetric | null> {
  try {
    const payload = {
      device_ids: deviceIds,
      col: statusType,
      from: from.toISOString(),
      to: to.toISOString(),
    };

    const resp: AxiosResponse = await axios.get(ZENER_CUMULATIVE_HRS_URL, {
      params: payload,
      cancelToken: cancelTokenSource?.token,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return {
      ...resp.data,
      from: dateParseISO(resp.data.from),
      to: dateParseISO(resp.data.to),
    };
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function downloadReport(deviceId: string): Promise<void> {
  await new Promise((resolve) => setTimeout(resolve, 3000));
  const fileUrl = "/Board Room Small_Report_20220518.pdf";
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", "Board Room Small_Report_20220518.pdf");
  document.body.appendChild(link);
  link.click();
}
