import React, { useEffect, useMemo, useState } from "react";
import SearchBar from "../../components/SearchBar";
import ZenerTable from "../../components/ZenerTable";
import ZenerDetailsModal from "../../components/ZenerDetailsModal";
import Loading from "../../components/Loading";
import { useZeners } from "../../hooks/useZeners";
import useAuth from "../../hooks/useAuth";
import "./Dashboard.scss";

export default function DashboardPage(): React.ReactElement {
  const { authUser } = useAuth();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBarFilter, setSearchBarFilter] = useState<string | null>(null);

  const {
    devices,
    devicesCount,
    loading,
    focusedDeviceId,
    setFocusedDeviceId,
    handleSetEnabled,
  } = useZeners(authUser, page, rowsPerPage, searchBarFilter);

  const focusedDevice = useMemo(() => {
    return focusedDeviceId
      ? devices.find((d) => d.deviceId() === focusedDeviceId) || null
      : null;
  }, [focusedDeviceId, devices]);

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    nextPage: number,
  ) => {
    setPage(nextPage);
  };

  useEffect(() => {
    setPage(0);
  }, [searchBarFilter]);

  return (
    <div className="dashboard">
      <div className="dashboard-middle">
        <SearchBar
          className="dash-search"
          label="SEARCH ZENER"
          onChange={setSearchBarFilter}
          value={searchBarFilter}
        />
        <span className="middle-notice">
          Click on the Zener below to view its details
        </span>

        <div className="middle-zeners">
          <ZenerTable
            devices={devices}
            query={searchBarFilter || ""}
            onSelect={setFocusedDeviceId}
            onSetEnabled={handleSetEnabled}
            devicesCount={devicesCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={setRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </div>
      </div>
      {focusedDevice && (
        <ZenerDetailsModal
          zener={focusedDevice}
          onClose={() => setFocusedDeviceId(null)}
          onReloadDevices={() => setPage((prevPage) => prevPage)} // Refresh the current page
        />
      )}
      {loading && <Loading overlay />}
    </div>
  );
}
