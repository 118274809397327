import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRenderer } from "../../hooks/threeJs/useRenderer";
import { useResizeHandler } from "../../hooks/threeJs/useResizeHandler";
import { useModelLoader } from "../../hooks/threeJs/useModelLoader";
import Axios, { CancelTokenSource } from "axios";
import { useSnackbar } from "notistack";
import { ColQueryOp, RoomScan } from "../../types";
import { getRoomScansFiles } from "../../networking/room_scans";
import { getRoomDimension, cancelRequest } from "../../utils/helpers";
import * as Sentry from "@sentry/browser";
import Loading from "../../components/Loading";
import "./SimulationDetails.scss";

export interface RoomDimension {
  x: number; // Width
  y: number; // Height
  z: number; // Depth
}

const SimulationDetails: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const { renderer, scene, camera } = useRenderer(canvasRef);
  useResizeHandler(canvasRef, camera, renderer);
  const [loading, setLoading] = useState(false);
  const roomScansCTSRef = useRef<CancelTokenSource | undefined>(undefined);
  const [roomScan, setRoomScan] = useState<RoomScan | null>(null);
  const [roomSize, setRoomSize] = useState<RoomDimension | null>(null);
  const scanName = roomScan?.name ?? "";
  const [modelUrl, setModelUrl] = useState<string | null>(null);
  const { loadModel } = useModelLoader(
    scene,
    camera,
    renderer,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    modelUrl!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    roomSize!,
  );
  const { enqueueSnackbar } = useSnackbar();

  const fetchRoomScan = useCallback(
    async (scan_id: string) => {
      setLoading(true);

      const cancelTimeout = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cancelRequest(roomScansCTSRef as any);
      }, 20);

      try {
        const [fetchedRoomScan] = await getRoomScansFiles(
          { scan_id: { value: scan_id, op: ColQueryOp.Eq } },
          roomScansCTSRef.current,
        );

        if (!fetchedRoomScan?.files) {
          enqueueSnackbar("No room scan data found.", { variant: "warning" });
          return;
        }

        const { json, obj } = fetchedRoomScan.files;
        if (!json || !obj) {
          enqueueSnackbar("Incomplete room scan files.", {
            variant: "warning",
          });
          return;
        }

        setRoomScan(fetchedRoomScan);
        setRoomSize(getRoomDimension(JSON.parse(json)));

        const objBlob = new Blob([obj], { type: "text/plain" });
        setModelUrl(URL.createObjectURL(objBlob));
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("Fetch canceled by user");
        } else {
          console.error(error);
          enqueueSnackbar("Failed to fetch room scan data.", {
            variant: "error",
          });
        }
      } finally {
        clearTimeout(cancelTimeout);
        setLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    const loadAndAddModel = async () => {
      if (roomScan && modelUrl) {
        try {
          await loadModel();
        } catch (error) {
          console.error("Failed to load model:", error);
        }
      }
    };
    loadAndAddModel();
  }, [modelUrl, roomScan, loadModel]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const scanId = query.get("scan_id");
    if (scanId) {
      fetchRoomScan(scanId as string);
    } else {
      Sentry.captureException({ error: "Scan ID is missing in query." });
      enqueueSnackbar(
        "There was a problem opening the simulation, please try again later.",
        { variant: "warning" },
      );
    }
  }, [fetchRoomScan, enqueueSnackbar]);

  return (
    <div className="simulation-details">
      {loading ? <Loading message="Loading data..." /> : <h1>{scanName}</h1>}
      <canvas
        ref={canvasRef}
        style={{
          width: "1000px",
          height: "500px",
          visibility: loading ? "hidden" : "visible",
          margin: "0 auto",
        }}
      />
    </div>
  );
};

export default SimulationDetails;
